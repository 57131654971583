@import '/src/Styles.scss';

.btn{
    background: $primary_color;
    border: none;
    padding: 16px 40px;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    box-shadow: '0 2px 4px rgba(0, 0, 0, 0.2)';
    cursor: pointer;
}

.btn.primary{
    color: $white;
    font-size: $normal;
}
.btn.primary:hover{
    background: #4198ac;
}

.btn.arrow{
    background: transparent;
    color: $white;
    font-size: $normal;
    border: 1.5px solid $white;
}

.btn.arrow:hover{
    background: $white;
    color: $black;
}