@import '/src/Styles.scss';

.container.about{
    background: $accent_white;
    padding: 40px 150px;
}

.container.about .about{
    border-radius: 16px 0 0 16px;
    // border: 2px $accent_grey solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.container.about .about .right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80%;
    margin: 3rem;
}
.container.about .about .left{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
}
.container.about .about .image-2{
    width: 60%;
  padding-top: 5rem;
    height:100%;
    display: flex;
    flex-direction: column;
   justify-content: flex-start;
}
.container.about .about .image-1{
    width: 35%;
    height:100%;
    display: flex;
    flex-direction: column;
   justify-content: flex-end;
}

.container.about .about img{
    width: 100%;
    border-radius: 30rem;
}
.container.about .about p{
    text-align: justify;
}
.container.about .about .bold{
    font-weight: bold;
}
.container.about .about .right h1{
    color: $primary_color;
}
.container.about .about .right h1,
.container.about .about .right p{
    margin-bottom: 12px;
}
a {
    text-decoration: none;
  }
  
// .container.about .about .left{
//     // width: 50%;
//     border-radius: 16px 0 0 16px;
// }

// .container.about .about .left img{
//     border-radius: 16px 0 0 16px;
//     object-fit: cover;
//     width: 100%;
//     height: 100%;
// }

// .container.about .about .right{
//     display: flex;
//     flex-direction: column;
// }

// .container.about .about .right .row{
//     display: flex;
//     flex-direction: row;
// }

// .about .right button{
//     margin: 10px;
//     padding: 12px;
// }

@media screen and (max-width: 960px) {
    .container.about{
        background: $accent_white;
        padding: 40px 100px;
    }

    .container.about .about{
        height: 80rem;
    }
    .container.about .about .image-2{
        width: 120%;
        margin-top:1rem;
        padding-top: 0;
    }
    .container.about .about .image-1{
        width: 70%;
    }
    .container.about .about .left{
        flex-direction: column;
    }
    .container.about .about{
        flex-direction: column;
    }
    .container.about .about p{
        width: 100%;
    }
    .container.about .about .right{
        padding-top: 2rem;
        width: 100%;
        margin:0;
        justify-content: flex-start;
    }
    // .container.about .about{
    //     flex-direction: column;
    //     margin: 0 auto;
    // }

    // .container.about{
    //     padding: 10px;
    // }

    // .container.about .about .left{
    //     height: 300px;
    //     border-radius: 16px 16px 16px 16px;
    //     margin-bottom: 24px;
    //     padding: 12px;
    // }

    // .container.about .about .left img{
    //     border-radius: 16px 16px 16px 16px;
    // }
}


@media screen and (max-width: 600px) {
    .container.about{
        background: $accent_white;
        padding: 20px 50px;
    }
}