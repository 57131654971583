@import '/src/Styles.scss';

.container.contacts {
    background: $accent_white;
    padding: 40px 150px;
}

.container.contacts .contact {
    border-radius: 16px 0 0 16px;
    // border: 2px $accent_grey solid;
    display: flex;
    flex-direction: row;
}

.container.contacts .contact .left {
    // width: 50%;
    height: 500px;
    border-radius: 16px 0 0 16px;
}

.container.contacts .contact .left img {
    border-radius: 16px 0 0 16px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.container.contacts .contact .right {
    display: flex;
    flex-direction: column;
}

.container.contacts .contact .right .row {
    display: flex;
    flex-direction: row;
}

.contact .right button {
    margin: 10px;
    padding: 12px;
}

.contact .quote {
    font-size: $heading;
    color: $primary_dark;
}

.email-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.error-text {
    color: red;
    font-size: 0.9rem;
    margin-left: 15px;
    margin-top: 0;
    transition: margin-top 0.2s ease;
}

.error-visible {
    margin-top: -10px;
}

.email-input-wrapper.has-error input {
    border-color: red;
}

@media screen and (max-width: 1110px) {
    .heros .heading {
        font-size: $heading;
    }
}

@media screen and (max-width: 960px) {
    .container.contacts .contact {
        flex-direction: column;
        margin: 0 auto;
    }

    .container.contacts {
        padding: 10px;
    }

    .container.contacts .contact .left {
        height: 300px;
        border-radius: 16px 16px 16px 16px;
        margin-bottom: 24px;
        padding: 12px;
    }

    .container.contacts .contact .left img {
        border-radius: 16px 16px 16px 16px;
    }

}

@media screen and (max-width: 447px) {
    .heros .heading {
        font-size: $subheading;
    }
}