@import '/src/Styles.scss';

.repo{
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: $white;
    width: 328px;
    box-shadow: rgba(0,0,0,0.05) 0px 4px 34px 14px;
}

.repo img{
    width: 328px;
    height: auto;
    border-radius: 16px 16px 0px 0px;
}

.repo .box{
    padding: 16px;
}

.repo .box .title{
    font-size: $normal;
    font-weight: $medium;
    text-align: left;
}

.repo .box .description{
    font-size: $normal;
    color: $grey_text;
    text-align: left;
    margin-top: 10px;
}

.repo .box .btns{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.btns button.social{
    margin-right: 7px;
    border-radius: 5px;
    padding: 5px;
    border: 2px solid $grey_text;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    background: linear-gradient(to bottom, #ffffff, #D7D7D7);
}

.btns button.social:active {
    transform: scale(0.85);
}

.btns button.social:hover{
    background: linear-gradient(to bottom, #ffffff, #b6b6b6);
}

.btns button.social img{
    height: 15px;
    width: 15px;
}

.btns button.social p{
    margin-left: 5px;
    font-size: $small;
    font-weight: $medium;
}