@import '/src/Styles.scss';

.container.researches{
    background-color: $accent_white;
}

.container.researches .column{
    padding: 40px 150px;
}

@media screen and (max-width: 1330px) {
    .container.researches .column{
        padding: 40px 0px;
        margin: 0 auto;
    }
}

.swiper-mobile-container{
    padding-top: 25px;
    width: 100%;
    background-color: $container_white;
    display: none;
}

.swiper-mobile-container .slide-controller{
    margin-top: 35px;
}

.swiper-mobile-container.research-route .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 595px;
}

.swiper-mobile-container.research-route .goal{
    height: 595px;
    margin: auto;
}

@media screen and (max-width: 1140px) {
    .container.goals{
        // display: none;
    }

    .swiper-mobile-container.research-route{
        // display: block;
    }
}
