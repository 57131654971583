@import '../Styles.scss';

.page .container{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page .container.goals{
    background: $container_white;
    // background: url('../assets/goal-background.png') no-repeat center center;
    background-size: cover;
}

// .page .container.goals,
// .page .container.pillars{
//     display: flex;
//     flex-direction: row;
// }

.container.goals .goals{
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    overflow-x: scroll;
    scroll-behavior: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    scrollbar-width: auto;
}

/* Add this CSS to your stylesheet */
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  width: 30px !important; /* Adjust the width as needed */
  height: 30px !important; /* Adjust the height as needed */
  background-size: 30px 30px !important; /* Adjust the background size */
//   background-color: $primary_color;
    // border-width: 2px;
}

/* Optional: Adjust the arrow icon size */
.swiper-container .swiper-button-next::after,
.swiper-container .swiper-button-prev::after {
  font-size: 22px !important; /* Adjust the font size of the arrow icon */
}

@media screen and (max-width: 920px) {
    .page .container{
        align-items: start;
        justify-content: start;
    }

    .goal{
        min-width: 328px;
    }

    .container.goals,
    .container.pillar{
        overflow-x: scroll;
        scroll-behavior: smooth;
    }

}

@media screen and (max-width: 1140px) {

    .container.goals .goals{
        margin: 0 auto;
        width: 328px;
    }
}

@media screen and (max-width: 960px) {

    .container.goals .goals{
        margin: 0 auto;
        width: 328px;
    }
}

/* ------------- Foundation Section -------------*/

.page .container.pillar{
    background: $container_white;
}

.container.pillar .pillars{
    display: flex;
    flex-direction: row;
    // width: 857px;
    padding: 40px 0px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}

@media screen and (max-width: 960px) {

    .container.pillar .pillars{
        margin: 0 auto;
        width: 259px;
        flex-direction: column;
    }

    .container.pillar .pillars .foundation{
        height: 287px;
    }
}


/* ------------- OUtcomes Section -------------*/

.container.outcome{
    display: flex;
    flex-direction: column;
    background: $accent_white;
}

.container.outcome .row{
    display: flex;
    flex-direction: row;
    padding: 40px 250px;
}

.container.outcome .row .mobile{
    display: none;
}

.container.outcome .row .desktop{
    display: flex;
}

.container.outcome .row .outcomes{
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.container.outcome .row .image{
    margin: 0px 30px;
    animation: fadeInUp 1s ease;
    // animation: MoveUpDown 5s linear infinite;
}

.swiper-mobile-container{
    padding-top: 25px;
    width: 100%;
    background-color: $container_white;
    display: none;
}

.swiper-mobile-container .slide-controller{
    margin-top: 35px;
}

.swiper-mobile-container .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 285px;
}

.swiper-mobile-container .foundation{
    height: 285px;
    margin: auto;
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(32px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

.container.outcome .row .outcomes .title{
    margin-bottom: 24px;
    font-size: $heading;
    font-weight: $medium;
    color: $primary_color;
}

.container.outcome .row .outcomes ul li{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.container.outcome .row .outcomes ul li p{
    margin-left: 16px;
}
.container.outcome .row .imageonly{
    border-radius: 50px;
}

@media screen and (max-width: 1330px) {
    .container.outcome .row{
        padding: 40px 150px;
    }
}

@media screen and (max-width: 960px) {
    .container.outcome .row{
        flex-direction: column;
        padding: 40px 100px;
    }

    .container.outcome .row .outcomes{
        margin-top: 16px;
    }

    .container.outcome .row .image{
        width: auto;
        margin: 0;
    }

    .container.outcome .row .imageonly{
        border-radius: 60px;
        align-self: center;
    }

    .container.outcome .row .mobile{
        display: flex;
    }
    
    .container.outcome .row .desktop{
        // display: none;
    }

    .container.outcome .row .outcomes .title{
        text-align: center;
    }

    .container.pillar{
        // display: none;
    }

    .swiper-mobile-container.home-route{
        // display: block;
    }


}

@media screen and (max-width: 480px) {
    .container.outcome .row{
        padding: 20px 60px;
    }

    .container.outcome .row .image{
        height: 280px;
        width: auto;
    }

    .container.outcome .row .image.collage{
        height: auto;
        width: 200px;
    }

    .container.outcome .row .mobile{
        display: flex;
    }
    
    .container.outcome .row .desktop{
        // display: none;
    }

    .container.outcome .row .outcomes .title{
        font-size: $subheading;
    }

    .container.outcome .row .outcomes ul li p{
        font-size: $small;
    }
}

//   #goal.container,
//   #journey.container{
//     flex-direction: column;
//   }

