@import '/src/Styles.scss';

.news-item{
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    max-width: 1120px;
}

.news-item img{
    border-radius: 7px;
}

.news-item .collage{
    display: grid;
    grid-template-columns: 220px 220px 220px 220px 220px;
    grid-template-rows:  50px 50px 50px 50px 50px;
    grid-template-areas: 
        "image1 image1 image1 image2 image2"
        "image1 image1 image1 image2 image2"
        "image1 image1 image1 image2 image2"
        "image1 image1 image1 image3 image4"
        "image1 image1 image1 image3 image4";
    // height: 281px;
    gap: 10px;
}

.collage .image1{
    grid-area: image1;
}
.collage .image2{
    grid-area: image2;
}
.collage .image3{
    grid-area: image3;
}
.collage .image4{
    grid-area: image4;
}

.collage .image1,
.collage .image2,
.collage .image3,
.collage .image4 {
  position: relative;
  overflow: hidden;
}

.collage .image1 img,
.collage .image2 img,
.collage .image3 img,
.collage .image4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.info .title{
    font-size: $subheading;
    font-weight: $semibold;
}

.info .date{
    font-size: $small;
    color: $grey_text;
    margin-bottom: 24px;
}

.info .content{
    text-align: left;
    font-size: $normal;
    margin-bottom: 12px;
    // max-width: 1280px;
}

@media screen and (max-width: 1240px) {
    .news-item{
        max-width: 890px;
    }

    .news-item .collage{
        display: grid;
        grid-template-columns: 170px 170px 170px 170px 170px;
        grid-template-rows:  50px 50px 50px 50px 50px;
        gap: 10px;
    }
}

@media screen and (max-width: 960px) {
    .news-item{
        max-width: 620px;
    }

    .news-item .collage{
        grid-template-columns: 200px 200px 200px;
        grid-template-rows:  70px 70px 70px;
        grid-template-areas: 
            "image1 image1 image1"
            "image1 image1 image1"
            "image2 image3 image4";
        // height: 281px;
        gap: 10px;
    }
}

@media screen and (max-width: 680px) {
    .news-item{
        max-width: 470px;
    }

    .news-item .collage{
        grid-template-columns: 150px 150px 150px;
        grid-template-rows:  60px 60px 60px;
        grid-template-areas: 
            "image1 image1 image1"
            "image1 image1 image1"
            "image2 image3 image4";
        // height: 281px;
        gap: 10px;
    }
}

@media screen and (max-width: 520px) {
    .news-item{
        max-width: 320px;
    }

    .news-item .collage{
        grid-template-columns: 100px 100px 100px;
        grid-template-rows:  50px 50px 50px;
        grid-template-areas: 
            "image1 image1 image1"
            "image1 image1 image1"
            "image2 image3 image4";
        // height: 281px;
        gap: 10px;
    }
}