@import '/src/Styles.scss';

nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: rgba($black, 0.5);
    background-color: $accent_white;
    padding: 20px 150px;
    position: fixed;
    width: 100vw;
    transition: 0.3s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 9999;
}

nav.scrolled{
    // background-color: $primary_darker;
}

#navlinks{
    display: flex;
    align-items: center;
    justify-content: center;
}

#navlinks li{
    list-style: none;
    padding: 0 20px;
    position: relative;
}

#navlinks li a{
    text-decoration: none;
    // color: $accent_grey;
    color: rgb(26, 26, 26);
    font-size: $normal;
    font-weight: $semibold;
    transition: 0.3s ease-in-out;
}

#navlinks li a.active{
    color: $primary_color;
    // color: $tertriary_color;
}

#navlinks li a:hover{
    color: $primary_color;
    // color: $tertriary_color;
}

#navlinks li a:hover::after,
#navlinks li a.active::after{
    content: "";
    width: 30%;
    height: 2px;
    background: $accent_white;
    // background: $tertriary_color;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile{
    display: none;
}

#mobile i{
    // color: $white;
    color: $primary_color;
    font-size: $heading;
    cursor: pointer;
}

// new css changes
#navlinks  > li{
    cursor: pointer;
}

#navlinks  > li > span{
    // color: $accent_grey;
    display: flex;
    align-items: center;
    justify-content: left;    
    color: rgb(26, 26, 26);
    font-weight: $semibold;
    font-size: $normal;
}

#navlinks .dropdown{
    min-width: 150px;
    display: none;
    // display: flex;
    flex-direction: column;
    // justify-content: left;
    align-items: flex-start;
    position: absolute;
    padding: 5px 0px;
    padding-bottom: 10px;
    transition: 0.3s all ease-in;
    background: $white;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
#navlinks .dropdown > li{
    margin: 5px;
}

#navlinks > li:hover .dropdown{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1920px) {
    nav{
        padding: 20px 260px;
    }
}

@media screen and (max-width: 1460px) {
    nav{
        padding: 20px 200px;
    }
}

@media screen and (max-width: 1400px) {
    nav{
        padding: 20px 100px;
    }
}

@media screen and (max-width: 1200px) {
    nav{
        padding: 20px 70px;
    }

    #navlinks{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 134px;
        right: -100vw;
        width: 100vw;
        height: 100vh;
        // background: $primary_darker;
        background-color: rgba($accent_white, 1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navlinks.active{
        right: 0px;
    }

    #navlinks li{
        margin-bottom: 25px;
    }

    .desktop{
        display: none;
    }

    #mobile{
        display: block;
    }

    #mobile i{
        font-size: $subheading;
    }

    #navlinks .dropdown{
        position: relative;
        display: flex;
        padding: 0;
        background: $accent_white;
        border-radius: 0px;
        box-shadow: none;
    }
}

@media screen and (max-width: 796px) {
    #navlinks{
        top: 97px;
    }
}