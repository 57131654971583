@import '/src/Styles.scss';

.container.news{
    background: $accent_white;
    padding: 32px 16px;
}

.container.news .news-list{
    // padding: 0px 150px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    
}