@import '../../Styles.scss';

.slides {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    justify-self: center;
    // height: 700px;
    overflow: hidden;
    width: 100%;
    padding: 0; /* Adjusted padding */
    margin-top: 0; /* Removed margin */
    
}


  

.slides img{
    width: 100%;
    height: auto;
}

.container {
    margin: 0;
    padding: 0;
    display: flex;
    background-color: $accent_white;
    align-items: center;
    justify-content: center;
}

.w-50 {
    justify-self: center;
    padding: 50px;
    align-self: center;
}

.slide-container {
    background-color: $accent_white;
    justify-content: center;
    width: 1100px;
    height: auto;
}

@media screen and (max-width: 1070px) {
    .slide-container {
        width: 800px;
    }
}

@media screen and (max-width: 755px) {
    .slide-container {
        width: 600px;
    }
}

@media screen and (max-width: 553px) {
    .w-50 {
        padding: 25px;
    }

    .slide-container.w-50{
        width: 100vw;
    }

    .slide-container {
        width: 450px;
    }
}