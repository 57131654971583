@import '/src/Styles.scss';

.container{
    margin: 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container.testimonial{
    background: $container_white;
    padding-bottom: 32px;
}

.container.testimonial .row{
    display: flex;
    // aspect-ratio: 16/9;
    width: 1075px;
    padding: 40px 0px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    
    // overflow: visible;
}

.testimonial .card{
    margin: 10px;
    padding: 32px;
    background: $white;
    // box-shadow: rgba(0,0,0,0.05) 0px 4px 34px 14px;
    min-width: 345px;
    border-radius: 16px;
    scroll-snap-align: start;
    
}

.card img{
    width: 112px;
    height: 112px;
    border-radius: 50%;
}

.card .person{
    display: flex;
    flex-direction: row;
}

.card .person .info{
    flex-direction: column;
    margin-left: 12px;
}

.person .info .name{
    font-size: $normal;
    font-weight: $bold;
}

.person .info .title{
    font-size: $small;
    color: $grey_text;
}

.card .comment{
    margin-top: 12px;
    font-size: $small;
    font-weight: $regular;
}


@media screen and (max-width: 1200px) {
    .container.testimonial .row{
        width: 710px;
    }
}

@media screen and (max-width: 920px) {
    .container.testimonial .row{
        width: 345px;
        margin: 0 auto;
    }
}