@import '/src/Styles.scss';

.container.repositories{
    background: $accent_white;
    padding: 32px 16px;
}

.container.repositories .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px; 
}

.containers {
    background: $accent_white;
    padding: 32px 64px ;
    justify-content: center;
    display: flex;
    align-items: center;
   
   
}

.head{
    color:$primary_color
}

.article-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.article{
    width: 1000px;
}

@media screen and (max-width: 1100px) {
    .container.repositories {
        justify-content: center;
    }

    .container.repositories .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .article{
        width: 600px;
    }
}

@media screen and (max-width: 756px) {

    .container.repositories .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .article{
        width: 400px;
    }
}

@media screen and (max-width: 420px) {

    .article{
        width: 250px;
    }
}