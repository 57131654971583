@import '/src/Styles.scss';

.container.projects{
    background: $accent_white;
    display: flex;
    flex-direction: column;
}

.link{
    color: $primary_color;
    text-decoration: none;
}

.row img.imageonly{
    height: 350px;
    width: auto;
    border-radius: 200px;
}

.swiper-mobile-container{
    padding-top: 25px;
    width: 100%;
    background-color: $container_white;
    display: none;
}

.swiper-mobile-container .slide-controller{
    margin-top: 35px;
}

.swiper-mobile-container.project-route .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 491px;
}

.swiper-mobile-container.project-route .goal{
    height: 491px;
    margin: auto;
}

@media screen and (max-width: 1140px) {
    .container.goals{
        // display: none;
    }

    .container.goals .goals{
        display: flex;
        flex-direction: column;
    }

    .swiper-mobile-container.project-route{
        // display: block;
    }
}

.videocontainer{
    display: flex;
    justify-content: center;
    padding: 50px;
    background: $container_white;

}

.video{
    height: 400px;
    width: 600px;
}

@media screen and (max-width: 900px) {
    .video{
        height: 200px;
        width: 300px;
    }
}
