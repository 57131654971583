
// color styles

$primary_color: #357F90;
$secondary_color: #7EC899;
$tertriary_color: #C6E9F5;
$primary_dark: #063F5C;
$primary_darker: #08222C;
$accent_grey: #D7D7D7;
$white: #FFFFFF;
$accent_white: #fafafa;
$container_white: #d6d6d6;//f6f6f6
$black: #000000;
$grey_text: #6e6e6e; //A9A9A9
$gradient: linear-gradient(to top, #357F90, #7EC899, #C6E9F5);

// font size

$normal: 16px;
$small: 14px;
$heading: 32px;
$midsubheading: 20px;
$subheading: 24px;
$huge: 68px;
$head: 96px;

// font weight

$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

@keyframes MoveUpDown{
    0%, 100%{
        bottom: 0;
    }
    50%{
        bottom: 30px;
    }
}

@keyframes MoveLeftRight{
    0%, 100%{
        right: 0;
    }
    50%{
        right: 20px;
    }
}