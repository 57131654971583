@import '/src/Styles.scss';

.heros{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 40px 150px;
}

.heros .heading{
    font-size: $huge;
    font-weight: $semibold;
    background-image: $gradient;
    background-clip: text;
    color: transparent;
    text-align: center;
    animation: fadeInUp 1s ease;
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(32px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

.heros .description{
    display: flex;
    font-size: $regular;
    color: $accent_grey;
    text-align: center;
    padding: 0px 120px;
}

@media screen and (max-width: 1330px) {
    .heros{
        padding: 40px 100px;
    }

    // .hero .heading{
    //     font-size: $huge;
    //     font-size: 96px;
    // }

    .heros .description{
        padding: 0px 80px;
    }
}

@media screen and (max-width: 965px) {
    .heros{
        padding: 20px 40px;
    }

    .heros .heading{
        font-size: $huge;
    }

    .heros .description{
        padding: 0px 5px;
        font-size: $small;
    }
}

@media screen and (max-width: 965px) {
    .heros .heading{
        font-size: 42px;
    }
}