* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a{
  cursor: pointer;
}

.backdrop{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

body .backdrop{
  background: url('./assets/hero-home-alt.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
}

body.leadershipacademy .backdrop{
  background: url('./assets/leadershipacademy.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

body.sustainableimpactprojects .backdrop{
  background: url('./assets/sip_new.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}
body.about .backdrop{
  background: url('./assets/about-us-new.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}
body.org-support .backdrop{
  background: url('./assets/organizational-support.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

body.communityresilience .backdrop{
  background: url('./assets/research.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

body.gallery .backdrop{
  background: url('./assets/gallery.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

body.news .backdrop{
  background: url('./assets/news.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

body.contact .backdrop{
  background: url('./assets/contact-us.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

.page{
  display: flex;
  flex-direction: column;
}

.youth-leadership .row{
  display: flex;
  align-items: center;
  justify-content: center;
}

.youth-leadership .image{
  max-width: 300px;
  height: 242px;
  display: flex;
  align-items: center;
  justify-content: center;
}
