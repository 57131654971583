@import '/src/Styles.scss';

.containers {
    background: $accent_white;
    padding: 32px 64px ;
    justify-content: center;
    display: flex;
    align-items: center;
   
   
}
.list{
    list-style-type: none;
}

.heading{
    color: $primary_color;
    font-size: $heading;
    padding: 0pd 0px 200px 0px;
    margin: 20px;
    
    .content{
        color: black;
        font-size: $normal;
        text-decoration: none ;
        margin-left: 25px;
    }
}


.containers .grid-containers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px; 
}


.file{
    cursor: pointer;
}

.fileitem:hover{
    color: $primary_color;
}

ul.list.content li a{
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 1100px) {
    .containers {
        justify-content: center;
    }

    .containers .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 756px) {
    .containers .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}