.input-box{
    position: relative;
    margin: 10px;
    // width: 250px;
}

.input-box input,
.input-box textarea{
    width: 100%;
    padding: 10px;
    border: 2px solid #357F90;
    border-radius: 7px;
    outline: none;
    font-size: 0.9em;
    // margin: 10px;
}

.input-box span{
    position: absolute;
    left: 0;
    padding: 10px;
    font-size: 0.9em;
    pointer-events: none;
    color: rgba(0,0,0,0.35);
    transition: 0.3s ease;
    text-transform: uppercase;
}

.input-box input:valid ~ span,
.input-box input:focus ~ span,
.input-box textarea:valid ~ span,
.input-box textarea:focus ~ span{
    color: aliceblue;
    transform: translateX(10px) translateY(-7px);
    font-size: 0.65em;
    padding: 0 10px;
    background: #357F90;
    border-radius: 5px;
}

.input-box textarea{
    resize: none;
    min-height: 68px;
    max-height: 500px;
}

.input-box textarea::-webkit-scrollbar{
    width: 0px;
}