@import '/src/Styles.scss';

.container{
    margin: 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container.patners{
    background: $container_white;
    padding-bottom: 32px;
}

.container.patners .row{
    display: flex;
    // align-items: center;
    // justify-content: center;
    //width: 1075px;
    padding: 40px 0px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    
}

.patners .partner{
    margin: 10px;
    display: flex;
    align-items: center;
    background: $white;
    min-width: 140px;
    border-radius: 16px;
    scroll-snap-align: start;
    
}

.partner img{
    width: 140px;
    height: auto;
    border-radius: 16px;
}


@media screen and (max-width: 1000px) {
    .container.patners .row{
        width: 460px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 512px) {
    .container.patners .row{
        width: 300px;
    }
}