@import '/src/Styles.scss';

.foundation{
    cursor: pointer;
    margin: 20px;
    background: $white;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 259px;
    // height: 241px;
    border-radius: 16px;
    scroll-snap-align: start;
    // box-sizing: content-box;
    transition: 0.3s all ease-in;
}

.foundation a{
    height: 100%;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.foundation:hover{
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 34px 14px;
}

.foundation img{
    height: 46px;
    width: auto;
    margin-bottom: 7px;
}

.foundation .title{
    color: $primary_color;
    font-size: $subheading;
    font-weight: $medium;
    text-align: center;
    margin-bottom: 7px;
    padding: 10px 0px;
}

.foundation .title:nth-child(0){
    margin-bottom: 2px
}

.foundation .description{
    text-align: center;
    font-size: $small;
    color: $grey_text;
}

@media screen and (max-width: 960px) {

    .foundation{
        min-width: 259px;
    }
}
