@import '/src/Styles.scss';

.container.gallery{
    background: $accent_white;
    background: #04171f;
    padding: 40px 150px;
}

.container.gallery .row{
    max-width: 1330px;
    margin: 0 auto;
}

.lg-react-element{
    column-count: 3;
    column-gap: 10px;
}

.container.gallery img{
    margin-bottom: 10px;
    width: 100%;
    display: block;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
}

.container.gallery img:hover{
    filter: opacity(0.9);
    transform: scale(1.01);
}

@media screen and (max-width: 1330px) {
    .container.gallery{
        padding: 40px 50px;
    }
}

@media screen and (max-width: 560px) {
    .container.gallery{
        padding: 20px 30px;
    }

    .container.gallery img{
        margin-bottom: 7px;
        border-radius: 7px;
    }

    .lg-react-element{
        column-gap: 7px;
    }
}