@import '/src/Styles.scss';


.flex-container {
    display: flex;
    align-items: center;
    border: none;
    width: 100%; 
    height: 300px;
    justify-content: center; 
    background-color: $accent_grey;

    .content {
        text-align: center;
        margin-left: 10px; 
    }

    .thumbnailimage {
        max-width: 150px; 
        max-height: 200px; 
        cursor: pointer;
        margin-right: 20px; 

        @media screen and (max-width: 768px) {
            margin-right: 10px; 
        }
    }
}

.download-button {
    background-color: $primary_color;
    color: $white;
    border: none;
    padding: 8px 16px;
    font-size: $normal;
    font-weight: $semibold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: $primary_dark;
  }