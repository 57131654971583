@import '/src/Styles.scss';

.research{
    display: flex;
    flex-direction: row;
    width: 1100px;
    border-radius: 18px;
    border: 2px $accent_grey solid;
    box-sizing: border-box;
    margin-bottom: 24px;
}

.research .left{
    position: relative;
    width: 380px;
    object-fit: cover;
    height: 430px;
    overflow: hidden;
    border-radius: 16px 0 0 16px;
}

.research .left img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px 0 0 16px;
    transition: all 1s ease;
}

.research .left img:hover{
    transform: scale(1.2) rotate(-10deg);
}

.research .left .date{
    padding: 8px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    color: $black;
    border-radius: 0 16px 0 16px;
}

.research .left .date:hover{
    background: rgba(255, 255, 255, 1);
}

.research .right{
    padding: 20px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    width: 720px;
}

.research .right p{
    font-size: $normal;
}

.research .right .title{
    font-size: $subheading;
    font-weight: $medium;
}

.research .right .subtitle{
    font-size: $midsubheading;
    font-weight: $medium;
    margin-top: 12px;
}

.research button.social{
    margin-top: 12px;
    margin-right: 7px;
    border-radius: 5px;
    padding: 5px;
    border: 2px solid $grey_text;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // max-width: 36px;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    background: linear-gradient(to bottom, #ffffff, #D7D7D7);
}

.research button.social:active {
    transform: scale(0.85);
}

.research button.social:hover{
    background: linear-gradient(to bottom, #ffffff, #b6b6b6);
}

.research button.social img{
    height: 15px;
    width: 15px;
}

.research button.social p{
    margin-left: 5px;
    font-size: $small;
    font-weight: $medium;
}

@media screen and (max-width: 1260px) {

    .research{
        width: 800px;
    }
}

@media screen and (max-width: 840px) {
    .research{
        display: flex;
        flex-direction: column;
    }

    .research{
        width: 500px;
    }

    .research .left{
        height: 300px;
        width: 100%;
        border-radius: 16px 16px 0px 0px;
    }

    .research .left img{
        border-radius: 16px 16px 0px 0px;
    }

    .research .left .date{
        border-radius: 0 16px 0 0;
    }

    .research .right{
        width: 100%;
    }
}

@media screen and (max-width: 540px) {
    .research{
        width: 300px;
    }

    .research .left{
        height: 150px;
    }

    .research .right{
        padding: 20px;
    }

    .research .right p{
        font-size: $small;
    }

    .research .right .title{
        font-size: $midsubheading;
    }

    .research .right .subtitle{
        font-size: $normal;
    }
    
}