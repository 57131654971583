@import '/src/Styles.scss';

footer{
    padding: 60px 150px;
    background: $primary_darker;
}

#upper-footer{
    display: flex;
    justify-content: space-between;
}

.footer-col{
    display: flex;
    flex-direction: column;
}

.footer-col ul li{
    list-style: none;
    margin-bottom: 25px;
    min-width: 140px;
}

.footer-col h3,
.footer-col p{
    color: $tertriary_color;
    font-size: $regular;
    margin-bottom: 25px;
}

.footer-col ul li a{
    color: $accent_grey;
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

.footer-col ul li a:hover{
    color: $tertriary_color;
    margin-left: 10px;
}

#lower-footer{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#lower-footer p{
    color: $accent_grey;
    font-size: $small;
}

#lower-footer .social{
    display: flex;
    min-width: 170px;
    justify-content: space-between;
}

#lower-footer .social a i{
    color: $accent_grey;
    transition: 0.3s ease-in-out;
}

#lower-footer .social a i:hover{
    color: $white;
}

@media screen and (max-width: 1920px) {
    footer{
        padding: 20px 260px;
    }
}

@media screen and (max-width: 1048px) {
    .footer-col ul li a {
        font-size: 14px;
    }
    footer{
        padding: 10px 40px;
    }
    .footer-col{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .footer-col p{
        margin-bottom: 7px;
    }
    .footer-col ul li{
        margin-bottom: 0px;
        
    }

    #upper-footer{
        justify-content: center;
        align-items: flex-start;
        gap: 50px;
    }

    .footer-col{
        margin-top: 28px;
    }

    .footer-col Button{
        margin-bottom: 14px;
    }

    #lower-footer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    #lower-footer p{
        margin-bottom: 14px;
    }
}

@media screen and (max-width: 680px) {
    #upper-footer{
        // padding-left: 30%;
        padding: auto;
        // flex-direction: column;
    }

    #upper-footer *{
        font-size: 12px;
    }
}

@media screen and (max-width: 550px) {
    #upper-footer{
        // padding-left: 20%;
        gap: 20px;
    }

    #upper-footer .left .footer-col p{
        margin-bottom: 0px;
    }

    #upper-footer .right .footer-col:nth-child(2) {
        margin-top: 12px;
    }
}
@media screen and (max-width: 450px) {
    #upper-footer{
        gap: 20px;
    }
    #upper-footer *{
        // padding-left: 10%;
        font-size: 12px;
        gap: 20px;
    }
}