@import '/src/Styles.scss';

.goal{
    margin: 20px;
    background: $white;
    // padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 328px;
    border-radius: 16px;
    scroll-snap-align: start;
}

.goal img{
    // width: 120px;
    // height: 120px;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
}

.goal-content{
    padding: 24px 16px;
}

.goal .title{
    font-size: $subheading;
    font-weight: $medium;
    text-align: center;
}

.goal span{
    color: $primary_color;
    font-size: $subheading;
    font-weight: $medium;
    text-align: center;
}

.goal .description{
    text-align: center;
    font-size: $small;
    color: $grey_text;
}


@media screen and (max-width: 1140px) {
    .goal{
        min-width: 328px;
    }
}

