@import '/src/Styles.scss';
.swiper-mobile-container{
    padding-top: 25px;
    width: 100%;
    background-color: $container_white;
    display: none;
}

.swiper-mobile-container .slide-controller{
    margin-top: 35px;
}

.swiper-mobile-container.org-sup-route .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 595px;
}

.swiper-mobile-container.org-sup-route .goal{
    height: 595px;
    margin: auto;
}

@media screen and (max-width: 1140px) {
    .container.goals{
        // display: none;
    }

    .swiper-mobile-container.org-sup-route{
        // display: block;
    }
}